import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function UploadFilesService(params) {
  console.log(params)
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
  
    let formData = new FormData();

    formData.append("file", params.file);
  
    return axios.post(`https://w9p93fyzk5.execute-api.us-east-1.amazonaws.com/prod/get_bill?file=${params.name}`, {formData} ,options)
    .then(response => {    
      console.log(response) 
    /*return {
        list: response.data,
        meta: response.meta
      };*/
    }).catch(err => {
      console.log(err)
    })
  }

export default {
  UploadFilesService,
};
  