<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 py-2">
                <h3 class="mb-0 text-primary">Agregar Factura</h3>
              </div>
              <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 py-3 row justify-content-end">
                <base-button
                  @click="goBack"
                  type="button"
                  class="btn btn-sm btn-primary"
                  >Historial de facturas
                </base-button>
              </div>
            </div>
            <div class="card-body p-0">
              <form ref="profile_form" @submit.prevent="handleSubmit">
                <loading-overlay :active.sync="loading" :is-full-page="true" />
                <base-input
                  label="No. factura:"
                  placeholder="No. factura"
                  prepend-icon="fas fa-file-invoice"
                  v-model="no_bill"
                  id="numero_factura"
                />
                <validation-error :errors="apiValidationErrors.no_bill" />

                <base-input label="Fecha emisión" alternative class="mb-3" id="fecha_emision">
                  <flat-picker
                    slot-scope="{ focus, blur }"
                    @on-open="focus"
                    @on-close="blur"
                    :config="config"
                    class="form-control datepicker"
                    v-model="fecha"
                  >
                  </flat-picker>
                </base-input>
                <validation-error :errors="apiValidationErrors.fecha" />
                <base-input
                  label="Nombre de Promociones:"
                  alternative                  
                  class="mb-3"
                  id="promociones"           
                >
               
                  <el-select
                    v-model="promociones.select"
                    placeholder="Promociones"
                    filterable    
                      
                  >
                
                    <el-option
                      v-for="option in promociones.multiple"
                      :key="option.value"
                      :label="option.label"
                      :value="option"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                <validation-error :errors="apiValidationErrors.nombre_promociones" />

                
                <div class="form-group">
                  <label class="form-control-label">
                    Imagen de la factura:
                  </label>
                  <div v-if="image && !pdf" class="profile-image card-img pb-4">
                    <img :src="`${image}`" class="profile-image argon-image" />
                  </div>
                  <div v-if="!image && !pdf" class="profile-image">
                   <img
                      src="../../../../public/img/file_invoice_placeholder.png"
                      width="100%"
                      class="argon-image"
                    /> 
                  </div>
                  <div v-if="pdf && !image" class="profile-image">
                    <img
                      src="../../../../public/img/pdf_placeholder.png"
                      width="100%"
                      class="argon-image"
                    /> 
                  </div>
                  <p class="mt-0 mb-3" style="font-weight: bold">
                    Tamaño máximo 15MB
                  </p>
                  <div class="image-upload">
                    <base-button
                      v-if="image || pdf"
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="removeImage"
                    >
                      <span>
                        <i class="fa fa-times" />
                        Remover
                      </span>
                    </base-button>
                    <base-button type="button" class="btn btn-sm btn-success">
                      <label v-if="!image && !pdf" for="imageInput" class="mb-0"
                        >Selecciona una imagen</label
                      >
                      <label v-else for="imageInput" class="mb-0"
                        >Cambiar</label
                      >
                      <input
                        id="imageInput"
                        ref="imageInput"
                        accept=".jpg, .jpeg, .png, .pdf"
                        type="file"
                        style="display: none"
                        @input="onFileChange"
                      />
                    </base-button>
                  </div>
                </div>

                <validation-error
                  :errors="apiValidationErrors.nombre_producto"
                />

                <div class="bg-warning py-1 py-1 px-3 " style="border-radius: 10px">
                  <h4 class="text-white my-0">¡Atención!</h4>
                  <small class="text-white"> Si las fotos no
                    son legibles o se ven manipuladas de cualquier manera, la persona queda
                    automáticamente descalificada.	</small>
                </div>
                <div class="my-4">
                  <base-button
                    type="button"
                    class="btn btn-primary"
                    native-type="submit"
                    :disabled="validate"
                    id="btn_subirfactura"
                  >
                    Agregar
                  </base-button>
                </div>
              </form>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb"; */
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es";
import { Select, Option } from "element-ui";
//import { MARCAS } from "../../../utilities/constants";
import axios from "axios";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { Modal } from "@/components";
import files from "../../../store/services/UploadFilesServices.js";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    /* RouteBreadCrumb, */
  },

  data() {
    return {
      config: {
        wrap: true,
        altFormat: "d-m-Y",
        altInput: true,
        allowInput: true,
        dateFormat: "m-d-Y",
        locale: Spanish,
      },
      loading: false,
      no_bill: null,
      fecha: new Date(),
      cantidad_vendida:null,
      sucursal: null,
      image: null,
      image_value: null,
      contador: null,
      promociones: {select: '', multiple: []},
      pdf: false,
      extention: null,
      modals: {
        question: false,
      },
      country_name: null,

      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      urlImage: "",
      fileInfos: [],
    };
  },

  methods: {
    getPromotion(){
      axios.get('https://api.clubdefacturas.com/api/v1/get-promotions', {headers: {  
      'Content-Type': 'application/json'
      }})
      .then((r) => {
          const promocion = r.data.result
          for(let i=0; i < promocion.length; i++){
            let NewPromotion = {"value": promocion[i].promotion_id, "label":promocion[i].name}
            this.promociones.multiple.push(NewPromotion)
          }
      })
      .catch((e) => {
          this.promociones.multiple = []
      })
      .finally(() => {
          
      })
    },
   async getDatetime() {
      await this.$store.dispatch("cutOffDate/getDatetime").then(() => {
        let response = this.$store.getters["cutOffDate/datetime"];
        if(response !="0000-00-00 00:00:00"){
          response = response.split(" ");
          const fecha = response[0].split("-");
          const hora = response[1].split(":");
          const final_date = new Date(fecha[0], parseInt(fecha[1]) - 1, fecha[2], hora[0],  hora[1],  hora[2]);
          this.contador = final_date.getTime() - new Date().getTime();
        }
      });
    },
    selectFile() {
      this.selectedFiles = this.$refs.file.files;
      
    },
    upload() {
      this.progress = 0;


      this.currentFile = this.selectedFiles.item(0);


      files.UploadFilesService(this.currentFile)
        .then(response => {
          console.log(response)
          this.message = response.data.message;
          return UploadService.getFiles();
        })
        .then(files => {
          this.fileInfos = files.data;
        })
        .catch(() => {
          this.progress = 0;
          this.message = "Could not upload the file!";
          this.currentFile = undefined;
          return
        });

      this.selectedFiles = undefined;
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    showSwal(type) {
      if (type === "success") {
        swal.fire({
          title: `Correcto`,
          text: "Respuesta correcta",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
          icon: "success",
        });
      } else if (type === "warning") {
        swal.fire({
          title: `!Atención!`,
          text: "Revise sus respuestas.",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-warning",
          },
          icon: "warning",
        });
      } else if (type === "fail") {
        swal.fire({
          title: `Incorrecto`,
          text: "Respuesta incorrecta",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-default",
          },
          icon: "error",
        });
      }
    },
    createImage(file) {
      const size = Math.round(file.size / 1000 / 1000);
      if (size > 15) {
        swal.fire({
          title: `¡Atención!`,
          html:
            "El tamaño del archivo es muy grande, intenta con otra imagen." +
            "<br/>" +
            "<b>Tamaño máximo 5MB</b>",
          buttonsStyling: false,
          icon: "warning",
          confirmButtonText: `Continuar`,
          customClass: {
            confirmButton: "btn btn-default",
          },
        });
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image_value = e.target.result;
        if (file.type != "application/pdf") {
          this.image = e.target.result;
          this.pdf = false;
          this.extention = "png";
        } else {
          this.pdf = true;
          this.extention = "pdf";
        }
      };
      reader.readAsDataURL(file);
    },

    removeImage() {
      this.image = null;
      this.image_value = null;
      this.extention = null;
      this.pdf = false;
    },
    goBack() {
      this.$router.push("/bills/history");
    },
    handleSubmit() {
      swal
        .fire({
          title: "¿Está seguro de que la información ingresada es correcta?",
          text: "Si las fotos no son legibles o se ven manipuladas de cualquier manera, la persona queda automáticamente descalificada.",
          confirmButtonText: `Continuar`,
          cancelButtonText: `Cancelar`,
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (this.image_value != null) {
              this.uploadFile();
            }else{               
              this.saveBill();
            }
          }
        });
    },
    async uploadFile() {
      this.loading = true;

      const file = {
        name: new Date().getTime() + "." + this.extention,
        content: this.image_value,
        extencion: this.extention,
      };
     
      try {        
        await this.$store.dispatch("bills/uploadFile", file);        
        const response = this.$store.getters["bills/path"];
        if (response.status != 204) {
          this.loading = false;
          //this.sendError("Upload bill", response, file.name);          
          this.$notify({
            type: "danger",
            message:
              "501 - Revisa tu conexión a internet e intenta con una imagen de baja resolución.",
          });
        } else {       
          await this.saveBill(response.data);
        }
      } catch (err) {
        this.sendError("Upload bill", err.response, file.name); 
        /* await this.$store.dispatch("errores/sendBillError", {
          error: err.response,
          data: file,
        }); */
        this.loading = false;
        this.$notify({
          type: "danger",
          message:
            "501 - Revisa tu conexión a internet e intenta con una imagen de baja resolución.",
        });
      } 
    },
    activate() {
    setTimeout(() => {}, 500);
  },
    async saveBill(image_link=null) {      
      this.loading = true;
      let fecha_factura = new Date();
      if (typeof this.fecha == "string") {
        fecha_factura = this.fecha.split("-");
        fecha_factura.forEach((e) => (e = parseInt(e)));
        fecha_factura = new Date(
          fecha_factura[2],
          fecha_factura[0] - 1,
          fecha_factura[1]
        );
        fecha_factura = this.formatDate(new Date(fecha_factura));
      } else {
        fecha_factura = this.formatDate(this.fecha);
      }
      let bill = {
        no_bill: this.no_bill,
        bill_date: fecha_factura,
        product_name: this.nombre_producto,
        link: image_link,
        country_date: this.formatDate(new Date()),
        promotion_id: this.promociones.select.value
      };
      try {
        await this.$store.dispatch("bills/add", bill);
        this.$notify({
          message: "Factura almacenada exitosamente.",
        });
        this.goBack();
      } catch (error) {
        this.sendError("Add Bill", error.response.data, bill);
        this.loading = false;
        let msj = "500 - Lo sentimos, no recibimos tus datos. Por favor inténtalo de nuevo.";
        if(error.response.ata.message){  
          msj=error.response.ata.message;
        }
        this.$notify({
          type: "danger",
          message:msj,
        });
      }
    },
    formatDate(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        "T" +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
    
    async getUserComplete() {
      try {
          await this.$store.dispatch("users/getComplete").then(() => {       
          const user = this.$store.getters["users/user_complete"];
          this.sucursal = user.pharmacy;
          this.country_name = user.country;
          this.getquestionparam.country = user.country_id;
          this.loading = false;
          this.getTrivia();       
        });
      } catch (err) {
          this.sendError("Get Complete user", err.response, ''); 
      }
     
    },

    async sendError(metodo, error, data) {
      await this.$store.dispatch("errors/sendError", {
          plataforma: "Tombola del éxito / Clavulin",
          metodo:  metodo,
          error:  JSON.stringify(error),
          data:  JSON.stringify(data)
        });
    },
  },
  mounted() {
    //this.getProducts();
    //this.getDatetime();
    //this.getUserComplete();
  },
  created(){
    this.getPromotion();
  },
  computed: {
    validate: function () {
      return (
        !this.no_bill ||
        !this.fecha ||
        !this.promociones.select /*||
        !this.sucursal /* ||
        !this.image_value */
      );
    },
  },
};
</script>
